import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/vQVxVirEmX0"
    bibleGroupSrc="https://www.docdroid.net/Cumd6N3/bible-group-homework-8-23-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/34410117"
  >
    <SEO title="Amos - Now What?" />
  </Layout>
)

export default SermonPost
